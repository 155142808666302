import { useParams, useNavigate } from 'react-router';
import { modules } from '../data';
import { useEffect } from 'react';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Text from '../../../components/Text';
import Container from '../../../components/Container';
import { ReactSVG } from 'react-svg';
import Clock from '../../../images/clock.svg';
import Calendar from '../../../images/calendar.svg';
import Hand from '../../../images/hand.svg';
import Icon1 from '../../../images/modul-icon-1.svg';
import Icon2 from '../../../images/modul-icon-2.svg';
import Icon3 from '../../../images/modul-icon-3.svg';
import Icon4 from '../../../images/modul-icon-4.svg';
import Icon5 from '../../../images/modul-icon-5.svg';
import Icon6 from '../../../images/modul-icon-6.svg';
import ArrowRight from '../../../images/arrow-right.svg';
import ModulCta from '../../../images/modul-cta.svg';
import ArrowRightBlack from '../../../images/arrow-right-black.svg';
import ModulCenter from '../../../images/modul-center.svg';
import ImageSection from '../../../components/Section/ImageSection';
import CardsSection from '../../../components/Section/CardsSection';
import CallToAction from '../../../components/Layout/CallToAction';
import MyAccordion from '../../../components/Accordion';
import Button from '../../../components/Form/Button';
import Gallery from '../../../components/Gallery';
import { slides } from '../../Home/slides';
import './Modul.scss';
import Spacer from '../../../components/Layout/Spacer';
import GlobalSection from '../../../components/Section/GlobalSection';
import { sectionBackgroundColor, sectionBlueBackgroundColor } from '../../Home';
import { Helmet } from 'react-helmet';

const Modul = () => {
  const navigate = useNavigate();
  const params = useParams();
  const currentModul = modules.find(
    (modul) => modul.moduleId === params.modulId
  );

  useEffect(() => {
    if (!currentModul) {
      navigate('/');
    }
  }, [currentModul, navigate]);
  return currentModul ? (
    <Layout>
      <Helmet>
        <title>{currentModul?.name}</title>
        <meta
          name="description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:title" content={currentModul?.name} />
        <meta
          property="og:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="twitter:title" content={currentModul?.name} />
        <meta
          property="twitter:description"
          content="Prijavi se na tečaj programiranja i postani web developer/ka!"
        />

        <meta property="og:url" content="https://kodiraonica.dev/prijavi-se" />

        <meta property="og:type" content="website" />

        <meta property="og:image" content={currentModul?.headerImage} />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta
          property="twitter:url"
          content="https://kodiraonica.dev/prijavi-se"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content={currentModul?.headerImage} />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />

        <meta name="twitter:site" content="@kodiraonica" />
        <meta name="twitter:creator" content="@kodiraonica" />

        <meta property="og:site_name" content="Kodiraonica" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="programiranje, kodiranje, Kodiraonica, web development, HTML, CSS, Javascript, React"
        />
      </Helmet>
      <Header hasSingleColumn={true} headerImage={currentModul.headerImage}>
        <Text type="h1" className="text-white">
          {currentModul?.name}
        </Text>
      </Header>

      <section
        style={{ paddingTop: 30, paddingBottom: 30 }}
        className="bg-blue"
      >
        <Container>
          <div className="row">
            <div className="col-xs-10 col-xs-offset-1">
              <div className="row justify-center">
                <span className="px-3 md:border-r-2 mb-4 md:mb-0 border-white col-xs-12 col-md-4">
                  <Text type="p" className="text-white flex items-center">
                    <ReactSVG className="mr-3 ml-3 " src={Calendar} />{' '}
                    <span>{currentModul?.duration}</span>
                  </Text>
                </span>

                <span className="px-3 md:border-r-2 mb-4 md:mb-0 border-white col-xs-12 col-md-4">
                  <Text type="p" className="text-white flex items-center">
                    <ReactSVG className="mr-3 ml-3 " src={Clock} />{' '}
                    <span>{currentModul?.time}</span>
                  </Text>
                </span>

                <span className="px-3 col-xs-12 col-md-4">
                  <Text type="p" className="text-white flex items-center">
                    <ReactSVG className="mr-3 ml-3" src={Hand} />{' '}
                    <span>{currentModul?.price}</span>
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <Text
          type="h3"
          className="text-blue text-center mb-4 lg:mb-7 font-bold"
        >
          {currentModul.title}
        </Text>
        <ImageSection
          heading={''}
          imageSrc={currentModul?.image}
          description={currentModul?.subTitle()}
          columnLeftClass="col-xs-12 col-md-5 text-center md:text-left"
          columnRightClass="col-xs-12 col-md-7"
        />
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBlueBackgroundColor}>
        <Text type="h1" className="text-center text-white mb-4 xl:mb-7">
          Prednosti Kodiraonice
        </Text>
        <div className="row">
          <div className="col-xs-12 col-lg-3">
            <div className="text-center text-white mb-4 xl:mb-6">
              <ReactSVG src={Icon1} className="flex justify-center" />
              <Text type="h5" className="mt-2 mb-2">
                Online predavanja
              </Text>
              <Text type="p">
                Sve što trebaš je računalo i želja za znanjem!
              </Text>
            </div>

            <div className="text-center text-white mb-4 xl:mb-6">
              <ReactSVG src={Icon5} className="flex justify-center" />
              <Text type="h5" className="mt-2 mb-2">
                Namijenjeno početnicima
              </Text>
              <Text type="p"> Krećemo od osnova i učimo korak po korak.</Text>
            </div>

            <div className="text-center text-white mb-4 xl:mb-6">
              <ReactSVG src={Icon2} className="flex justify-center" />
              <Text type="h5" className="mt-2 mb-2">
                Čvrsti temelji
              </Text>
              <Text type="p">
                {' '}
                Savladat ćeš sve za početak uspješne karijere u programiranju.
              </Text>
            </div>
          </div>
          <div className="col-xs-12 col-lg-6 hidden lg:flex justify-center">
            <ReactSVG src={ModulCenter} />
          </div>
          <div className="col-xs-12 col-lg-3">
            <div className="text-center text-white mb-4 xl:mb-6">
              <ReactSVG src={Icon4} className="flex justify-center" />
              <Text type="h5" className="mt-2 mb-2">
                Izrada portfoliija
              </Text>
              <Text type="p">
                Radimo na konkretnim projektima koje možeš pokazati
                poslodavcima.
              </Text>
            </div>

            <div className="text-center text-white mb-4 xl:mb-6">
              <ReactSVG src={Icon3} className="flex justify-center" />
              <Text type="h5" className="mt-2 mb-2">
                Stručni predavači
              </Text>
              <Text type="p">
                Mentori su iskusni programeri s dugogodišnjim iskustvom.
              </Text>
            </div>

            <div className="text-center text-white mb-4 xl:mb-6">
              <ReactSVG src={Icon6} className="flex justify-center" />
              <Text type="h5" className="mt-2 mb-2">
                Rad u manjim grupama
              </Text>
              <Text type="p">
                Predavači se posvećuju izazovima svakog polaznika.
              </Text>
            </div>
          </div>
        </div>
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <ImageSection
          heading="Što ćeš naučiti na tečaju?"
          imageSrc={currentModul.keyImage}
          description={currentModul.learningOutcomes()}
        />
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <Text type="h1" className="text-blue text-center mb-4 xl:mb-7">
          Program tečaja
        </Text>
        <div className="xl:mb-4">
          <CardsSection
            cardsColumn="col-xl-4 mb-4"
            cards={currentModul.program}
          />
        </div>
      </GlobalSection>

      <CallToAction
        buttonLink="/prijavi-se"
        title="Početak tečaja"
        buttonText="Upisni obrazac"
        description="Novi tečaj počinje 05. ožujka 2025. godine! Ostalo je još nekoliko mjesta, rezerviraj svoje na vrijeme."
      />

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <div className="row">
          <div className="col-xs-12 col-xs-12 ">
            <MyAccordion items={currentModul.accordionItems} />
            <Button
              text="Pogledaj česta pitanja"
              icon={<ReactSVG src={ArrowRightBlack} />}
              className="is-black mt-[30px] float-right"
              to="/faq"
            />
          </div>
        </div>
      </GlobalSection>

      <GlobalSection backgroundColor={sectionBackgroundColor}>
        <ImageSection
          heading={currentModul.teacher}
          description={currentModul.teacherDescription}
          imageSrc={currentModul.teacherImage}
          buttons={[
            {
              text: 'O nama',
              link: '/about',
              className: 'is-pink mb-4',
            },
          ]}
        />
      </GlobalSection>

      <GlobalSection
        className="pb-0"
        backgroundColor={sectionBlueBackgroundColor}
      >
        {' '}
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            {' '}
            <ImageSection
              heading="Promijeni svoju karijeru i uđi u "
              headingAccentText="IT svijet!"
              description="Web programiranje nije samo budućnost - nego i sadašnjost. Iskoristi svoje potencijale i nauči novu vještinu koja će ti donijeti poslovni i osobni napredak!"
              imageSrc={ModulCta}
              buttons={[
                {
                  text: 'Upiši se',
                  link: '/prijavi-se',
                  className: 'is-white mb-4',
                  icon: <ReactSVG src={ArrowRight} />,
                },
              ]}
              isReverse
            />
          </div>
        </div>
      </GlobalSection>

      <Gallery slides={slides} />

      {/* <CallToAction
        title="Zanimaju te detalji edukacije?"
        description="Pripremili smo syllabus koji sadrži sve informacije o tečaju programiranja!"
        buttonText="Preuzmi plan rada"
        hasInput={true}
        inputPlaceholder="Unesi email adresu"
        inputName="subscribe-email"
        onClick={() => {}}
      /> */}

      <Spacer height={80} backgroundColor="#F9F9FA" />
    </Layout>
  ) : (
    <Text type="h1">Loading...</Text>
  );
};

export default Modul;
